const routes = [
  {
    path: "/settings/search/",
    component: () => import("../pages/search/index.vue"),
    meta: { title: "titleSearch", layout: "main-sidebar" },

    children: [
      {
        path: "search-restrictions",
        component: () => import("../pages/search/restriction/index.vue"),
        meta: { title: "titleSearchRestriction", layout: "main-sidebar" }
      },
      {
        path: "auto-suggest",
        component: () => import("../pages/search/autosuggest/index.vue"),
        meta: { title: "titleAutoSuggest", layout: "main-sidebar" }
      },
      {
        path: "hitlist",
        component: () => import("../pages/search/hitlist/index.vue"),
        meta: { title: "titleHitlist", layout: "main-sidebar" }
      }
    ]
  }
];

export default { routes };
