const routes = [
  {
    path: "/settings/personalization/",
    component: () => import("../pages/personalization/index.vue"),
    meta: { title: "titlePersonalization", layout: "main-sidebar" },
    children: [
      {
        path: "documentview",
        component: () => import("../pages/personalization/documentview/index.vue"),
        meta: { title: "titleDocumentView", layout: "main-sidebar" }
      },
      {
        path: "layout",
        component: () => import("../pages/personalization/layout/index.vue"),
        meta: { title: "titleLayout", layout: "main-sidebar" }
      },
      {
        path: "accessibility",
        component: () => import("../pages/personalization/accessibility/index.vue"),
        meta: { title: "titleAccessibility", layout: "main-sidebar" }
      },
      {
        path: "portal",
        component: () => import("../pages/personalization/portal/index.vue"),
        meta: { title: "titlePortals", layout: "main-sidebar" }
      },
      {
        path: "language",
        component: () => import("../pages/personalization/language/index.vue"),
        meta: { title: "titleLanguage", layout: "main-sidebar" }
      }
    ]
  }
];

export default { routes };
