const routes = [
  {
    path: "/settings/dataprotection/",
    component: () => import("../pages/dataprotection/index.vue"),
    meta: { title: "titleDataProtection", layout: "main-sidebar" },

    children: [
      {
        path: "personal-data",
        component: () => import("../pages/dataprotection/personalData/index.vue"),
        meta: { title: "titlePersonalData", layout: "main-sidebar" }
      },
      {
        path: "protection",
        component: () => import("../pages/dataprotection/protection/index.vue"),
        meta: { title: "titleProtection", layout: "main-sidebar" }
      }
    ]
  }
];

export default { routes };
