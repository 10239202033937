const routes = [
  {
    path: "/settings/notification/",
    component: () => import("../pages/notification/index.vue"),
    meta: { title: "titleNotifications", layout: "main-sidebar" },

    children: [
      {
        path: "newsletters",
        component: () => import("../pages/notification/newsletters/index.vue"),
        meta: { title: "titleNewsletters", layout: "main-sidebar" }
      },
      {
        path: "magazines",
        component: () => import("../pages/notification/magazines/index.vue"),
        meta: { title: "titlemagazines", layout: "main-sidebar" }
      },
      {
        path: "module-content",
        component: () => import("../pages/notification/moduleContent/index.vue"),
        meta: { title: "titleModuleContents", layout: "main-sidebar" }
      },
      {
        path: "search-jobs",
        component: () => import("../pages/notification/searchJobs/index.vue"),
        meta: { title: "titleSearchJobs", layout: "main-sidebar" }
      }
    ]
  }
];

export default { routes };
