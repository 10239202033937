import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import notification from "./notification.ts";
import search from "./search.ts";
import personalization from "./personalization.ts";
import dataprotection from "./dataprotection.ts";

declare module "vue-router" {
  interface RouteMeta {
    title: string;
    layout: string;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: "/settings",
    name: "home.index",
    component: () => import("../pages/index.vue"),
    meta: { title: "titleHome", layout: "main" }
  },
  {
    path: "/welcome",
    name: "welcome.index",
    component: () => import("../pages/welcome/index.vue"),
    meta: { title: "titleWelcome", layout: "main" }
  },
  {
    path: "/",
    redirect: () => {
      return { path: "/settings" };
    }
  },
  {
    path: "/debug",
    name: "Development",
    component: () => import("../pages/debugging/index.vue"),
    meta: { title: "titleDebug", layout: "main" }
  },
  ...notification.routes,
  ...search.routes,
  ...personalization.routes,
  ...dataprotection.routes,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../components/TheErrorMessage.component.vue"),
    meta: { title: "titleError", layout: "error" }
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
